<template>
    <zw-sidebar @shown="shown" :title="$t('multichannel.article.title.article')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-card :header="$t('multichannel.article.group.basic')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <zw-input-group v-model="form.api_sku"
                                                name="api_sku"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.name"
                                                name="name"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.purchase_price"
                                                name="purchase_price"
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.sales_price"
                                                name="sales_price"
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.weight"
                                                name="weight"
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                                <b-button
                                    @click="detectWeight(form)"
                                    variant="info"
                                    :title="$t('common.title.update')"
                                >
                                    <font-awesome-icon icon="sync"/>
                                    {{ $t('common.button.check_weight') }}
                                </b-button>
                            </b-col>

                            <b-col cols="3" v-if="form.source.api_type == 'magento'">
                                <zw-input-group v-model="form.attribute_set_id"
                                                name="attribute_set_id"
                                                type="number"
                                                min="0"
                                                step="1"
                                                :label-prefix="labelPrefix"
                                                :readonly="true"
                                ></zw-input-group>
                                <b-button
                                    @click="detectSetId(form)"
                                    variant="info"
                                    :title="$t('common.title.update')"
                                >
                                    <font-awesome-icon icon="sync"/>
                                    {{ $t('common.button.check_set_id') }}
                                </b-button>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.category_id"
                                                 :options="getCategoryOptions() | optionsVL"
                                                 name="category"
                                                 :label-prefix="labelPrefix"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <zw-select-group v-model="form.type"
                                                 :options="getTypes() | optionsVV"
                                                 name="type"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <label>{{ $t(labelPrefix + 'description') }}</label>
                                <zw-ckeditor v-model="form.description"></zw-ckeditor>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <label>{{ $t(labelPrefix + 'short_description') }}</label>
                                <zw-ckeditor v-model="form.short_description"></zw-ckeditor>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card v-if="form.source.api_type == 'otto-market'"
                        :header="$t('multichannel.article.group.otto')"
                >
                    <b-card-text>
                        <b-row>
                            <b-col cols="4">
                                <zw-input-group v-model="form.ean"
                                                name="ean"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="4">
                                <search-field v-model="form.brand"
                                              name="brand"
                                              :label-prefix="labelPrefix"
                                              :url="channelBrandSearchUrl"
                                              :placeholder="$t('common.label.common_search')"
                                ></search-field>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="3" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ChannelArticleModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {
                source: {}
            },
            labelPrefix: 'multichannel.article.label.',
            channelBrandSearchUrl: window.apiUrl + '/multi-channel/brand/search',
        }
    },
    methods: {
        ...mapGetters('ChannelArticle', ['getChannelArticle']),
        ...mapGetters('Category', ['getCategoryOptions']),
        ...mapGetters('Articles', ['getTypes']),
        shown() {
            const articles = this.$store.dispatch('ChannelArticle/fetchChannelArticle', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptions')
            const articleTypes = this.$store.dispatch('Articles/fetchTypes')

            Promise.all([articles, articleTypes, categories])
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getChannelArticle()}))
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        detectWeight(product) {
            this.$store.dispatch('Multichannel/checkWeight', product)
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.shown()
                })
        },
        detectSetId(product) {
            this.$store.dispatch('Multichannel/checkSetId', product)
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.shown()
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('ChannelArticle/saveChannelArticle', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
}
</script>